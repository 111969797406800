import "./App.css";
import ImageGallery from "./ImageGallery";
import { mainTheme } from "./theme";
import { Stack, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

function App() {
  const largeScreen = useMediaQuery("(min-width:800px)");

  return (
    <ThemeProvider theme={mainTheme}>
      <div
        style={{
          backgroundColor: "#faf6f2",
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          sx={{ px: "10%", mx: "auto" }}
          style={{
            minHeight: "100vh",
            maxWidth: "1200px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              letterSpacing: largeScreen ? "-18px" : "-10px",
              paddingTop: "20px",
              fontSize: largeScreen ? "100px" : "70px",
              marginBottom: "-20px",
            }}
          >
            PROTOJOPS
          </Typography>
          <Typography
            style={{
              letterSpacing: "15px",
              color: "grey",
              fontSize: "12px",
              marginBottom: "20px",
            }}
          >
            ESTABLISHED MMXVII
          </Typography>
          <ImageGallery></ImageGallery>
        </Stack>
      </div>
    </ThemeProvider>
  );
}

export default App;
