import React, { useState, useEffect, useRef } from "react";
import {
  ImageList,
  Stack,
  ImageListItem,
  CircularProgress,
  Fade,
  Modal,
} from "@mui/material";
import imageDirectory from "./image_directory";
import useMediaQuery from "@mui/material/useMediaQuery";

const ImageGallery = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const page = useRef(1);
  const pageSize = 1000;

  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setSelectedImage(e);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const largeScreen = useMediaQuery("(min-width:800px)");

  // Handle scroll events
  // const handleScroll = () => {
  //   if (
  //     document.getElementById("footer").getBoundingClientRect().bottom <=
  //     window.innerHeight
  //   )
  //     loadImages(false);
  // };

  useEffect(() => {
    // Load initial images when the component mounts
    loadImages(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // addScrollListener();
  }, []);

  // const addScrollListener = () => {
  //   window.addEventListener("scroll", handleScroll);
  // };

  const loadImages = async (mount) => {
    console.log("loading images");
    if (mount) {
      page.current = 1;
      setImages(imageDirectory.slice(0, page.current * pageSize));
      // setLoading(false);
    } else {
      // setLoading(true);
      page.current += 1;
      setImages(imageDirectory.slice(0, page.current * pageSize));
      // setLoading(false);
    }
  };

  return (
    <Stack direction="column" alignItems="center">
      <div style={{ outline: "none" }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="light-box"
          aria-describedby="light-box"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {selectedImage && (
              <img
                style={{
                  maxHeight: "90vh",
                  maxWidth: "90vw",
                }}
                alt="zoomed"
                src={require(`${selectedImage}`)}
              />
            )}
          </div>
        </Modal>
      </div>

      {loading && (
        <CircularProgress style={{ marginTop: "20px", color: "grey" }} />
      )}
      <ImageList
        variant="masonry"
        cols={largeScreen ? 3 : 1}
        gap={10}
        style={{ height: loading ? "0px" : "" }}
      >
        {images.map((image) => (
          <Fade in={!loading} key={image} timeout={2000}>
            <ImageListItem>
              <img
                onClick={() => handleOpen(image)}
                alt="work"
                src={require(`${image}`)}
                loading="lazy"
                style={{
                  cursor: "pointer",
                }}
              />
            </ImageListItem>
          </Fade>
        ))}
      </ImageList>

      {/* <div id="footer">
        {loading && <CircularProgress />}
        {noDataMessage && <Typography>No more images to load</Typography>}
        {!noDataMessage && !loading && (
          <Typography variant="h6" sx={{ p: 3 }}>
            Scroll to load more
          </Typography>
        )}
      </div> */}
    </Stack>
  );
};

export default ImageGallery;
