import { createTheme } from "@mui/material/styles";
const mainTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
      contrastText: "#f2f0f7",
    },
    anchor: {
      dark: "#000000",
      medium: "#757575",
      mediumDark: "#696766",
    },
    secondary: {
      main: "#5c5856",
    },
    accent: {
      main: "A0FF7E",
    },
    background: {
      light: "#444444",
      default: "#333333",
      paper: "#141414",
      medDark: "#2b2b2b",
      dark: "#272727",
    },
    text: {
      primary: "#cacccf",
      secondary: "#DEDEDE",
      dark: "#BFBFBF",
    },
    error: {
      main: "#8a1919",
    },
    info: {
      main: "#000000",
    },
    success: {
      main: "#000000",
    },
    type: "dark",
    warning: {
      main: "#903630",
    },
  },
});

export { mainTheme };
